.form-label {
	color: var(--Gray-100, var(--Default-Gray-100, #161616));
	font-family: 'IBM Plex Sans';
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 128.571% */
}
.characterImage {
	width: 100%;
	height: 100%;
	max-width: 220px;
	max-height: 180px;
	border-radius: 13px;
}

.input-container {
	position: relative;
	display: flex;
	align-items: center;
}

.formInput {
	width: 100%;
	padding: 6px 15px;
	padding-right: 66px; /* Add padding to ensure the text doesn't overlap the character count */
	box-sizing: border-box;
	border-radius: 8px;
	border: 1px solid #bcbed2;
}

.formDescription {
  margin-top: 16px;
	width: 100%;
	padding: 6px 15px;
	box-sizing: border-box;
	border-radius: 8px;
	border: 1px solid #bcbed2;

}
.formDescription:focus-visible {
  /* border: 1px solid #bcbed2 !important; */
  outline: 1px solid #166fc9 !important;
}

.char-count {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-size: 12px;
	color: gray;
}
.rangeFlex {
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-bottom: 10px;
  flex-wrap: wrap;
  flex: 1;
}
.rangeFlex-firstbox {
	width: 40%;
  min-width: 100px;
	max-width: 100px;
}

.rangeFlex-firstbox input {
	padding: 6px 15px;
  width: 100%;
}
.keyInput {
	padding: 6px 8px;
	width: 100%;
}
.selectScenario-box {
	display: flex;
	gap: 5px;
}
.selectScenario-box select {
	max-width: 150px;
	padding: 5px;
	border-radius: 8px;
	border: 1px solid #bcbed2;
}
.promptTextArea{
	width: 100%;
	min-height: 250px;
	height: auto;
	padding: 10px;
}
