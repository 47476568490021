.formContainer {
    /* max-width: 400px; */
    margin: 0 auto;
    padding: 20px 0px;
  }
  
  .formBox {
    margin-bottom: 20px;
  }
  
  .formBox label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formBox input[type="text"],
  .formBox input[type="email"] {
    width: 100%;
    padding: 16px 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .formContainer {
      max-width: 100%;
    }
  }
  