@font-face {
	font-family: 'Inter';
	src: url('./Assets/fonts/Inter/web/InterVariable.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Inter-Italic';
	src: url('./Assets/fonts/Inter/web/Inter-LightItalic.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

.container {
	padding: 0;
	width: 90%;
	margin: 0 auto;
}
.containerLoader {
	position: relative;
}
.bodyLoader {
	position: absolute;
	top: 0;
	bottom: 0;
}
.container-fluid {
	padding-right: 15px;
	padding-left: 15px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.logutBtn {
	border: none;
	background-color: transparent;
}
/* //css for chips */
ul.ks-cboxtags {
	list-style: none;
	padding: 20px 0;
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

ul.ks-cboxtags li {
	display: inline;
}

ul.ks-cboxtags li label {
	display: inline-block;
	background-color: #f5f5f5;

	color: #656565;
	border-radius: 3px;
	white-space: nowrap;
	margin: 3px 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	transition: all 0.2s;
}

ul.ks-cboxtags li label {
	padding: 8px 12px;
	cursor: pointer;
}

ul.ks-cboxtags li label::before {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 12px;
	padding: 2px 6px 2px 2px;
	content: '\f067';
	transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
	content: '\f00c';
	transform: rotate(-360deg);
	transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label {
	/* border: 2px solid #1bdbf8; */
	background-color: #12bbd4;
	color: #fff;
	transition: all 0.2s;
}

ul.ks-cboxtags li input[type='checkbox'] {
	display: absolute;
}

ul.ks-cboxtags li input[type='checkbox'] {
	position: absolute;
	opacity: 0;
}
/* ul.ks-cboxtags li input[type="checkbox"]:focus+label {
    border: 2px solid #e9a1ff;
} */
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes zoomInOut {
	0%,
	100% {
		transform: scale(1); /* Original size */
	}
	50% {
		transform: scale(1.2); /* Zoom to 120% of the original size */
	}
}

.recordingAnimation {
	animation: zoomInOut 2s infinite; /* Continuous zoom in and out */
}
.micTitle-primary {
	font-family: 'IBM Plex Sans Condensed', sans-serif;
	color: var(--Gray-1, var(--Hover-Gray-90, #333));
	text-align: center;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.16px;
}
.micTitle-secondary {
	color: var(--Gray-1, var(--Hover-Gray-90, #333));
	text-align: center;
	font-family: 'IBM Plex Sans Condensed', sans-serif;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px; /* 75% */
	letter-spacing: 0.16px;
}
.comment-wrapper {
	transition: all 0.2s linear;
}

.active {
	background-color: #1c75ba;
	border-radius: 4px;
	fill: white;
}

.msg .commentIcon {
	margin: 5px;
	width: 15px;
	height: auto;
}

.send-comment {
	margin-top: 9px;
	position: relative;
	transform: translateX(14px);
}

.send-comment input {
	color: #7d89a1;
	padding: 8px;
	padding-right: 35px;
	border: 1px solid #ffffff;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(19, 52, 102, 0.14);
	outline: none;
}

.send-comment input::placeholder {
	color: #7d89a1;
	font-weight: 400;
}

.send-comment img {
	margin-right: 8px;
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
	cursor: pointer;
	background-color: #dde3ed;
	border-radius: 50%;
	padding: 4.8px;
}

.coachImage {
	background-color: #6929c4;
	width: 48px;
	height: 48px;
	aspect-ratio: 1;
	border-radius: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.coachAnimation {
	position: relative;
}

.coachAnimation::before {
	content: '';
	aspect-ratio: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background: inherit;
	border-radius: inherit;
	z-index: -1;
	animation: animate-wave 2s infinite;
}

.micImage {
	background-color: rgba(13, 98, 212);
	width: 50px;
	aspect-ratio: 1;
	border-radius: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}
.micAnimation {
	position: relative;
}

.micAnimation::before {
	content: '';
	aspect-ratio: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background: inherit;
	border-radius: inherit;
	z-index: -1;
	animation: animate-wave 2s infinite;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
	color: transparent;
	background-color: transparent;
	border-color: transparent;
}
.btn:focus-visible,
.btn:focus {
	outline: none;
	box-shadow: none;
}
.MuiTypography-body2 img {
	max-width: 100%; 
	height: auto ;
}
@keyframes animate-wave {
	0% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
	25% {
		transform: translate(-50%, -50%) scale(1.1);
		opacity: 0.9;
	}
	50% {
		transform: translate(-50%, -50%) scale(1.2);
		opacity: 0.8;
	}
	75% {
		transform: translate(-50%, -50%) scale(1.3);
		opacity: 0.7;
	}
	100% {
		transform: translate(-50%, -50%) scale(1.5);
		opacity: 0.6;
	}
}

@media (max-width: 767px) {
	.container {
		width: 95%;
	}
}

.custom-tooltip {
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.label {
	font-weight: bold;
	color: #333;
}
