body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	height: 100vh;
	overflow: auto !important;
}

#root {
	height: 100vh;
}

.App {
	height: 100%;
}

p {
	margin-bottom: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
