.upload-container {
	border: 2.5px dashed var(--Grey-02, #b3b1c9);
	padding: 16px;
	text-align: center;
	margin-top: 16px;
	border-radius: 16px;
	cursor: pointer;
}

.icon-wrapper {
	display: flex;
	justify-content: center;
}

.icon-div {
	width: max-content;
	padding: 8px;
	border: 2.5px dashed #006bc5;
	border-radius: 4px;
}

.button-wrapper {
	display: flex;
	margin-top: 32px;
	gap: 16px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
	border-radius: 8px !important;
}

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-eqpfi5-MuiAccordionSummary-content {
	margin: 20px 0 10px 0 !important;
}

div#panel1-header {
	min-height: 12px !important;
}

.feedback-wrapper {
	display: flex;
	gap: 32px;
	margin-top: 30px;
}

.meeting-feedback-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 0 16px;
	/* margin-top: 30px; */
	width: 100%;
}

ul.MuiList-root {
	background: none !important;
}

@media screen and (max-width: 1050px) {
	.feedback-wrapper {
		display: block;
		margin-top: 30px;
	}
	.feedback-wrapper > * {
		margin-bottom: 32px; /* Add bottom margin to space out elements */
	}
}

@media screen and (max-width: 1050px) {
	.meeting-feedback-wrapper {
		display: block;
		/* margin-top: 30px; */
	}
	.meeting-feedback-wrapper > * {
		margin-bottom: 32px; /* Add bottom margin to space out elements */
	}
}
