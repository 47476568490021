.navInside-section {
	border-bottom: 1px solid #dde3ed;
	position: sticky;
	top: 0;
	z-index: 999;
	background-color: white;
}

.inside-nav-box {
	padding: 11px 32px;
	display: flex;
	align-items: center;
}

.inside-nav {
	display: flex;
	align-items: center;
	gap: 16px;
	min-width: 300px;
	max-width: 300px;
}
.inside-nav img {
	width: 45px;
	height: 46px;
}
.inside-nav p {
	color: var(--black, #133466);
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
	margin: auto 0;
}
.breadcrumb-box {
	margin-bottom: 0;
	max-width: calc(100% - 420px);
}
.breadcrumb-box p {
	font-family: Raleway;
	margin: auto 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.16px;
	width: 200px;
}
.breadcrumb {
	margin-bottom: 0 !important;
}
