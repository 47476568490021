* {
	box-sizing: border-box;
	font-family: 'IBM Plex Sans', sans-serif;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.feedbackbox {
	padding: 14px 24px;
	border-right: 1px solid #8d8d8d;
	min-width: 325px;
	background: #f4f4f4;
	min-height: calc(100vh - 187px);
	position: relative;
}
.conversation-btn-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	height: calc(100vh - 540px);
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0px 6px 14px 24px;
}
.bg-dark-gray {
	background: #4f4c4c !important;
}
.conversation-btn-wrapper button.btn.white-btn {
	position: relative;
	min-width: 276px;
	max-width: 276px;
}
.archive-icon img {
	max-width: 100%;
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.archive-icon {
	display: none !important;
	cursor: pointer !important;
}

/* Show the archive button on hover of the parent container */
.conversation-item:hover .archive-icon {
	display: block !important;
}
button.btn.white-btn.bg-dark-gray .archive-icon img {
	filter: invert(1);
}
button.btn.white-btn.bg-dark-gray .archive-icon {
	background: transparent;
}
.archive-icon {
	background: transparent;
	width: 30px;
	height: 30px;
	padding: 5px;
}
button.btn.white-btn:hover .archive-icon {
	display: block;
}
.chat-feedback-box {
	border: 1px solid #8d8d8d;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 70px);
	/* overflow-y: auto; */
}

.feedbackbox h4 {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.16px;
	font-weight: normal;
	margin: 0 0 10px;
	color: #333;
}
.progress-chart {
	padding: 0px;
}
.feedback-card {
	display: flex;
	flex-direction: column;
	border: 1px solid #bdbdbd;
	margin-bottom: 16px;
	border-radius: 8px;
	/* width: auto; */
	min-width: 300px;
	padding: 16px;
	background-color: white;
}
.progress-chart span.progress-percent {
	padding: 0px;
	border-radius: 50px;
	background: #dcdcdc;
	display: block;
	margin-bottom: 10px;
	font-size: 24px;
	line-height: 20px;
	font-weight: 700;
	color: #f2f2f2;
}
span.progress-percent.progressred {
	color: rgba(255, 85, 85, 1);
	background-color: transparent;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 111.111% */
	letter-spacing: 0.16px;
}
span.progress-percent.progressfull {
	color: #35965f;
	background-color: transparent;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 111.111% */
	letter-spacing: 0.16px;
}
span.progress-percent.progressinfo {
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 111.111% */
	letter-spacing: 0.16px;
	color: #f3d84f;
	background-color: transparent;
}
.feedback-card h2 {
	color: var(--black, #133466);
	font-family: Outfit;
	text-transform: capitalize;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 111.111% */
	letter-spacing: 0.16px;
}

.sideNav-TimeStamp {
	text-align: center;
	color: var(--Grey, #7d89a1);
	font-family: Outfit;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}
.sideNav-timeStampBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 2px 0;
}
.sideNav-line {
	width: 30%;
	height: 1px;
	background-color: #e6eaef;
}
.main-wrapper {
	background: #fff;
}
.feedbackbox .btn-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}
button.btn.black-btn {
	background: #161415;
	border-radius: 0;
	padding: 15px;
	text-align: center;
	width: 100%;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	letter-spacing: 0.16px;
	font-weight: 400;
}
button.btn.blue-btn {
	border-radius: 100px;
	background: var(--Blue, #1c75ba);
	padding: 15px;
	text-align: center;
	width: 100%;
	color: #fff;
	font-family: Outfit;
	vertical-align: middle;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: center;
	text-transform: capitalize;
}
button.btn.newWhite-btn {
	border-radius: 100px;
	background: #fff;
	padding: 15px;
	text-align: center;
	width: 100%;
	color: #1c75ba;
	font-family: Outfit;
	vertical-align: middle;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: center;
	text-transform: capitalize;
	border: 1px solid #1c75ba;
}
button.btn.blue-btn svg {
	margin-left: 8px;
}
button.btn.white-btn {
	background: #fdf6fa;
	border-radius: 0;
	padding: 15px;
	text-align: center;
	width: 100%;
	color: #7e90ab;

	font-family: Outfit;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.sideBox-parent {
	padding: 0 16px 16px 16px;
}
.listitem-sidebar {
	/* border: 1px solid var(--Stroke, #dde3ed); */
	display: flex;
	gap: 16px;
	width: 100%;
}
.listitem-sidebar a {
	color: var(--black, #133466);
	font-family: 'IBM Plex Sans';
	/* padding: 20px 32px 20px 33px; */
	text-decoration: none;
	width: 100%;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 18px;  */
	letter-spacing: 0.16px;
	align-items: center;
	margin-top: auto;
}
.listBorder-active {
	/* border: 1px solid var(--Stroke, #dde3ed); */
	background: #bce0fc;
}
.listBorder {
	max-height: fit-content;
	border-top: 1px solid var(--Stroke, #dde3ed);
}
.profilePicture-sidenav {
	width: 40px;
	height: 40px;
	border-radius: 40px;
}
.message-wrapper,
.message-wrapper-without-rec {
	padding: 10px 33px;
}
.message-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	margin-bottom: 6px;
	height: calc(100vh - 512px);
	/* height: 100vh; */
	overflow-y: auto;
	margin: 10px 0;
}
.message-wrapper-without-rec {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	margin-bottom: 6px;
	height: calc(100vh - 265px);
	overflow-y: auto;
}
.fullHeight {
	height: calc(100vh - 90px);
}
.msg {
	padding: 7px 12px;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	line-height: 130%;
	font-weight: 500;
	max-width: 84%;
	margin-bottom: 4px;
	color: #1a1a1a;
	width: auto;
	border-radius: 8px;
	border: 1px solid #c2c2c2;
	font-family: 'Outfit', sans-serif;
	font-optical-sizing: auto;
	cursor: pointer;
}

.comment-block {
	padding: 9px 14px;
	transform: translateX(28px);
}

.comment {
	border: 1.5px solid #1c75ba;
	padding: 7px 12px;
	border-radius: 8px;
}

.msg span {
	font-family: 'Outfit', sans-serif;
}
.sender-box .msg {
	background: #eee;
}

.sender-box .transparentMsg {
	background: #fff;
}

.msg img {
	width: 20px;
	height: auto;
}
.sender-box {
	text-align: right;
}
.receive-box p,
.sender-box p {
	font-size: 14px;
	line-height: 130%;
	font-weight: 500;
	margin: 0;
	color: #6c6c6c;
	font-family: 'Outfit', sans-serif;
}

.chatbox {
	background: #fff;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	/* width: calc(100% - 325px); */
	/* overflow-y: auto; */
}
.recommendation-box {
	/* background: #f5efff; */
	/* margin: auto 24px 39px; */
	padding: 16px;
	max-height: 250px;
	overflow: scroll;
}
.recommendation-box h5 {
	font-size: 16px;
	line-height: 1;
	margin: 0 0 24px;
	font-weight: 600;
}
.recommendation-suggest p {
	padding: 16px;
	margin: 0;
	border-radius: 8px;
	border: 1px solid #1c75ba;
	font-size: 16px;
	line-height: normal;
	color: #000;
	width: 100%;
}
.recommendation-suggest {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
.sender-input-box {
	background: #fff;
	padding: 10px 24px;
}
.inputtime p {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.16px;
	margin: 0;
	color: #525252;
}
.inputtime img {
	width: 12px;
	height: auto;
}
.inputtime {
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: flex-end;
	margin-bottom: 3px;
}
.inputbox textarea.form-control {
	padding: 16px;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.16px;
	padding-right: 60px;
	color: #161616;
	box-shadow: none;
	border-radius: 12px;
	border: 1px solid #bcbed2;
}
.inputbox textarea.form-control::placeholder {
	color: #a8a8a8;
}
.micBox {
	width: max-content;
	height: 200px;
	display: flex;
	position: absolute;
	top: 46px;
	right: 50%;
	transform: translate(-50%, -50%);
	left: 50%;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
}
.inputbox .btn.circle-btn img {
	width: 30px;
	height: auto;
}
.inputbox .btn.circle-btn {
	width: 80px;
	/* height: 42px;
  	border: 1px solid #322d41;
	border-radius: 50%; */
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	z-index: 10;
}

.btn:active {
	border: none;
}
.inputbox {
	position: relative;
	margin-bottom: 4px;
}
.mikeButton {
	position: absolute;
	top: 25px;
	right: 15px;
}
.mikeButton img {
	width: 20px !important;
}
.sender-input-box > p {
	font-size: 12px;
	color: #525252;
	line-height: 16px;
	letter-spacing: 0.32px;
	margin: 0 0 16px;
}
.progress-bar.bg-success {
	background: #217e6a !important;
	width: 100%;
}
.progress-bar.bg-info {
	background: #f2c94c !important;
	width: 50%;
}
.progress-bar.bg-red {
	background: rgba(255, 85, 85, 1) !important;
	width: 50%;
}
.progress-chart .progress {
	height: 10px;
	background: #e6eaef;
}

.feedback-card,
.recommendation-card {
	position: relative;
}

.feedback-card:hover .tooltip,
.recommendation-card:hover .tooltip {
	opacity: 1;
}
.conversation-item {
	margin-bottom: 10px; /* Adjust spacing between items */
}

.listType-Conversation {
	color: #7e90ab !important;
	font-family: Outfit;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
}
.Select-listType-Conversation {
	color: var(--Blue, #1c75ba) !important;
	font-family: Outfit;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 500 !important;
	line-height: normal;
}

.feedback-card .tooltip {
	position: absolute;
	top: 90%;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	opacity: 0;
	transition: opacity 0.3s;
	transition-delay: 0.1s;
	z-index: 999999;
}

.recommendation-card .tooltip {
	position: absolute;
	/* bottom: -1px; */
	width: 100%;
	left: 0;
	/* transform: translateX(-50%); */
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	opacity: 0;
	transition: opacity 0.3s;
	transition-delay: 0.1s;
}
.boxShowChild {
	display: block;
	/* position: relative; */
}
.boxHoverParent:hover .boxShowChild {
	display: block !important;
	/* bottom: 0px; */
	/* top: 0px; */
}
.customData-input {
	padding: 1px;
	border-radius: 3px;
	margin-right: 10px;
}
@media (max-width: 1366px) {
	.conversation-btn-wrapper {
		padding: 0px 24px 14px 24px;
	}
}
@media (max-width: 991px) {
	.feedbackbox {
		min-width: 280px;
		padding: 14px 18px;
	}
	.progress-chart span.progress-percent {
		padding: 0;
		margin-bottom: 5px;
		font-size: 20px;
	}
	.feedback-card h2 {
		color: var(--black, #133466);
		font-family: Outfit;
		text-transform: capitalize;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		text-transform: capitalize;
		line-height: 20px; /* 111.111% */
		letter-spacing: 0.16px;
	}
	.msg {
		font-size: 14px;
	}
	.recommendation-suggest p {
		padding: 10px;
		font-size: 14px;
	}
	.recommendation-box {
		margin: auto 24px 30px;
		padding: 12px 0 12px 12px;
	}
	.chat-feedback-box,
	.simulation-feedback-box {
		min-height: calc(100vh - 187px);
	}
	/* .message-wrapper {
		height: calc(100vh - 470px);
	} */
	.message-wrapper-without-rec {
		height: calc(100vh - 270px);
	}
	.conversation-btn-wrapper {
		padding: 0px 18px 14px 18px;
	}
}
@media (max-width: 767px) {
	.feedbackbox {
		min-width: 250px;
		padding: 14px 14px;
	}
	.sender-input-box {
		padding: 10px 28px;
	}
	button.btn.black-btn {
		padding: 12px;
	}
	button.btn.white-btn {
		padding: 12px;
	}
	.message-wrapper,
	.message-wrapper-without-rec {
		padding: 10px 14px;
	}
	.recommendation-box {
		margin: auto 14px 30px;
	}
}
@media (max-width: 575px) {
	.chat-feedback-box,
	.simulation-feedback-box {
		flex-wrap: wrap;
		min-height: auto;
		height: auto;
	}
	.feedbackbox {
		min-width: auto;
		width: 100%;
	}
	.progress-chart {
		padding: 12px;
	}
	.progress-chart span.progress-percent {
		padding: 0px;
		margin-bottom: 5px;
		font-size: 18px;
	}
	button.btn.black-btn {
		padding: 10px;
	}
	button.btn.white-btn {
		padding: 10px;
	}
	.sender-input-box > p {
		margin: 0 0 15px;
	}
	.recommendation-box {
		margin: auto 14px 20px;
	}
	.msg {
		max-width: 90%;
	}
	.sender-input-box {
		padding: 10px 14px;
	}
	.chatbox {
		min-height: auto;
		width: 100%;
	}
	/* .message-wrapper,
	.message-wrapper-without-rec {
		height: fit-content;
	} */
	.conversation-btn-wrapper {
		height: calc(100vh - 500px);
	}
}
