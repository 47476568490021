.progressTab-select{
    max-width: 360px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border: 2px solid #1C75BA;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #555555;
    font-size: 12px;
    font-weight: 800;
    padding: 15px 10px;
    border-radius: 8px;
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="%23555555" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat; /* Custom arrow */
    background-position: right 10px center; /* Position the arrow */
    background-size: 15px; /* Size of the arrow */
    padding-right: 30px; /* Make space for the arrow */
}