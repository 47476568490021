.simulatingChat-text{
width: 100%;
padding: 16px;
box-sizing: border-box;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.16px;
padding-right: 60px;
color: #161616;
box-shadow: none;
border-radius: 12px;
border: 1px solid #bcbed2;
}