.avatar-window {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	z-index: 10;
	pointer-events: auto;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	background: linear-gradient(to bottom, transparent 40%, gray 70%, black 100%);
	z-index: 5;
}

.video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	overflow: hidden;
}

.video-element {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.close-button {
	position: absolute;
	top: 13px;
	right: 13px;
	width: 43px;
	height: 43px;
	background-color: rgba(0, 0, 0, 0.76);
	cursor: pointer;
	z-index: 30;
	border-radius: 50%;
	pointer-events: auto;
}

.button-container {
	display: flex;
	justify-content: right;
}

.next-button {
	background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5025) 0%, rgba(0, 0, 0, 0.67) 99.99%);
	border-radius: 13px;
	padding: 5px 15px;
	z-index: 30;
	display: inline-block;
	margin: 15px;
}

.next-button button {
	margin: 0 20px;
	font-weight: 700;
	font-size: 20px;
	line-height: 36px;
	color: white;
	background: none;
	border: none;
}

.close-button span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	font-weight: bold;
	color: white;
}

.text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5025) 0%, rgba(0, 0, 0, 0.67) 99.99%);
	border-radius: 13px;
	padding: 5px 15px;
	z-index: 20;
	width: auto;
	flex-shrink: 0;
}

.logo-container {
	display: flex;
	align-items: center;
	background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5025) 0%, rgba(0, 0, 0, 0.67) 99.99%);
	border-radius: 13px;
	padding: 5px 15px;
	z-index: 20;
	width: auto;
	flex-shrink: 0;
	gap: 10px;
}

.text-container,
.logo-container img {
	max-height: 80px;
	width: auto;
	object-fit: contain;
}

.text-title {
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: white;
}

.text-subtitle {
	font-weight: 400;
	font-size: 14px;
	line-height: 36px;
	color: white;
}

.text-description-container {
	overflow-y: auto;
	max-height: 20vh;
	margin-left: 15px;
	margin-top: 30px;
	margin-bottom: 30px;
	padding-right: 10px;
}

.text-description {
	font-weight: 400;
	font-size: 30px;
	line-height: 36px;
	color: white;
}

.text-section {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 76.5%, rgba(0, 0, 0, 0) 100%);
}
.flex-container {
	margin: 15px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
