.token-limit-message {
	background-color: #ffdddd;
	border: 1px solid #ff4c4c;
	border-radius: 5px;
	color: #d8000c;
	padding: 10px 15px;
	margin: 10px 0;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
}
