.center {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.sign-in-button {
	color: #278bd8;
	background-color: transparent;
	border-radius: 100px;
	border: 1px solid #278bd8;
	text-align: center;
	margin: 4px 2px;
	cursor: pointer;
	display: flex;
	width: 158px;
	height: 50px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
